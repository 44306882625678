document.addEventListener('DOMContentLoaded', () => {
  let swiperInitialized = false;

  const initializeSwiperIfNeeded = () => {
    if (document.querySelector('swiper-container') && !swiperInitialized) {
      swiperInitialized = true;
      import('swiper/element/bundle').then((swiperBundle) => {
        swiperBundle.register();
        initializeSwiper();
      }).catch((error) => {
        console.error('Error importing Swiper:', error);
      });
    }
  };

  // Initial check if the swiper-container is already in the DOM
  initializeSwiperIfNeeded();

  // MutationObserver to detect if a <swiper-container> is added to the DOM later
  const mutationObserver = new MutationObserver(() => {
    initializeSwiperIfNeeded();
  });

  // Observe the entire document body for changes
  mutationObserver.observe(document.body, { childList: true, subtree: true });
});

function initializeSwiper() {
  const swipers = [
    { selector: '#home-swiper-stories-1', params: { injectStyles: [`.swiper { position: unset; } .swiper-button-prev, .swiper-button-next { border: 1px solid #ffffff; border-radius: 50%; width: 50px; height: 50px; box-shadow: 0px 0px 7px #999; background-color: white; margin-top: 0 !important; z-index: 999999; } .swiper-button-prev { left: -45px; } .swiper-button-next { right: -45px; } .swiper-button-prev svg, .swiper-button-next svg { width: 10px; color: gray; } @media(max-width:1024px){ .swiper-button-prev { left: -5px; } .swiper-button-next { right: -5px; } .swiper-button-prev, .swiper-button-next { bottom: -82px !important; top: auto; width: 40px; height: 40px; } }`], on: { slideChangeTransitionStart: function () { }, slideChangeTransitionEnd: function () {} } } },
    { selector: '#home-swiper-stories-2', params: { breakpoints: { 1024: { slidesPerView: 5, spaceBetween: 0 } } } },
    { selector: '#career-slider', params: { initialSlide: 1, injectStyles: [`.swiper-pagination .swiper-pagination-bullet { background-color: gray; } @media(min-width:768px){ .swiper-pagination {display:none} }`] } },
    { selector: '#art-gallery', params: { slidesPerView: 2, spaceBetween: 15, breakpoints: { 768: { slidesPerView: 4, spaceBetween: 20 } }, injectStyles: [`.swiper { position: unset; } .swiper-button-prev, .swiper-button-next { border: 1px solid #ffffff; border-radius: 50%; width: 50px; height: 50px; box-shadow: 0px 0px 7px #999; background-color: white; } .swiper-button-prev { left: -65px; } .swiper-button-next { right: -65px; } .swiper-button-prev svg, .swiper-button-next svg { width: 12px; color: gray; } @media(max-width:768px){ .swiper-button-prev, .swiper-button-next {display: none} }`] } },
    { selector: '#hero-service-2', params: { slidesPerView: 1, spaceBetween: 0, centeredSlides: true, breakpoints: { 1024: { slidesPerView: 3, centeredSlides: true, spaceBetween: 0 } } } },
    { selector: '#hero-service-3', params: { slidesPerView: 2, spaceBetween: 20, breakpoints: { 1024: { slidesPerView: 5, spaceBetween: 30 } } } },
    { selector: '#pricing-swiper', params: { initialSlide: 1, injectStyles: [`.swiper-pagination .swiper-pagination-bullet { background-color: white; }`] } },
  ];

  swipers.forEach(swiper => {
    const swiperEl = document.querySelector(swiper.selector);
    if (swiperEl) {
      Object.assign(swiperEl, swiper.params);
      swiperEl.initialize();
    }
  });
}
