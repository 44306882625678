let livesubmitLoaded = false;
const options = {
  root: null, // viewport
  rootMargin: "0px", // no margin
  threshold: 0.1, // 10% of the target visible
};

// callback function to handle intersection
const callback = async (entries, observer) => {
  entries.forEach(async (entry) => {
    if (entry.isIntersecting && !livesubmitLoaded) {
      const [{ initializeApp }, { getFirestore, doc, onSnapshot }] =
        await Promise.all([
          import("firebase/app"),
          import("firebase/firestore"),
        ]);

      // brevo
      function sendSubscribeToBrevo(list_id, name, email) {
        var ItemJSON = `{"list_id":${list_id},"email":"${email}","name":"${name}"}`;
        console.log(ItemJSON);
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
          if (this.readyState == 4 && this.status == 200) {
            var result = JSON.parse(this.responseText);
            console.log(result);
          }
        };
        xhttp.open("POST", "https://api.penji.co/api-user/add-brevo-by-id", true);
        xhttp.setRequestHeader("Content-type", "application/json");
        xhttp.send(ItemJSON);
      }

      // google drive
      function sendGoogleSheetRedirect(name, email, industry) {
        var today = new Date();
        var date = today.getMonth() + 1 + "/" + today.getDate() + "/" + today.getFullYear();
        jQuery.ajax({
          type: "GET",
          url:
            "https://script.google.com/macros/s/AKfycbxxf7R4YKb4R_3Y1lVeHoeNCQzeOvWHhL3lG5ID9U8u7Dd99n92Nxz0afHqAR5sqKx6Nw/exec?Date=" +
            date + "&Email=" + email + "&FirstName=" + name + "&Industry=" + industry + '&DemoVer=' + 'live-demo-x',
          crossDomain: true,
          success: function (result) {
            console.log(result);
          },
        });
      }
      // google drive for live demo
      function sendGoogleSheetRedirectLiveDemo(name, email, industry) {
        var today = new Date();
        var date = today.getMonth() + 1 + "/" + today.getDate() + "/" + today.getFullYear();
        jQuery.ajax({
          type: "GET",
          url:
            "https://script.google.com/macros/s/AKfycbxxf7R4YKb4R_3Y1lVeHoeNCQzeOvWHhL3lG5ID9U8u7Dd99n92Nxz0afHqAR5sqKx6Nw/exec?Date=" +
            date + "&Email=" + email + "&FirstName=" + name + "&Industry=" + industry + "&DemoVer=" + "live-demo-x",
          crossDomain: true,
          success: function (result) {
            console.log(result);
          },
        });
      }
      // google drive for sched demo
      function sendGoogleSheetRedirectSchedDemo(name, email, industry) {
        var today = new Date();
        var date = today.getMonth() + 1 + "/" + today.getDate() + "/" + today.getFullYear();
        jQuery.ajax({
          type: "GET",
          url:
            "https://script.google.com/macros/s/AKfycbxaOIPVvj4c9A4yjtZ4v1SboYt4qpfyeQR99ttqZpjDTsUl380DbdGtsBz2BL4bYt06_w/exec?Date=" +
            date + "&Email=" + email + "&FirstName=" + name + "&Industry=" + industry + "&DemoVer=" + "sched-demo-x",
          crossDomain: true,
          success: function (result) {
            console.log(result);
          },
        });
      }

      // handle process join live demo
      function handleProcessJoinLiveDemo(join_id, name, email, industry) {
        const unsub = onSnapshot(doc(db, "join_live_demo", join_id), (doc) => {
          const data_join_live = doc.data();
          data_join_live['id'] = join_id;
          console.log("current data: ", data_join_live);
          localStorage.setItem('data_join_live', JSON.stringify(data_join_live));
          if (accept !== data_join_live.accept) {
            accept = data_join_live.accept;
            sendGoogleSheetRedirectLiveDemo(name, email, industry);
            if (accept == 0) { // default
              // unsubscribe after 3 min
              loadProgressBar3Min();
              my_time_out = setTimeout(() => {
                console.log("qua 3 phut team ko tao call");
                window.location.href = "https://penji.co/live-demo-schedule";
              }, 3 * 60 * 1000);

            } else if (accept == 1) { // supporter pickup & wait customer
              clearTimeout(my_time_out);
              console.log("show step 3 meeting");
              document.getElementById("step2").classList.add("hidden");
              document.getElementById("step3_meeting").classList.remove("hidden");
              document.getElementById("host-name").innerText = data_join_live.host_name;
              document.getElementById("link-meeting").href = data_join_live.link_meeting;

              // without click after 2 min
              my_time_out = setTimeout(() => {
                console.log("qua 2 phut customer ko click call");
                window.location.href = "https://penji.co/live-demo-success";
              }, 2 * 60 * 1000);

              // demo call success
              document.getElementById("link-meeting").addEventListener("click", function(event) {
                event.preventDefault();
                window.open(this.href, "_blank");
                window.location.href = "https://penji.co/live-demo-success";
              });

            } else { // -1: late, 2: supporter mask as completed, 3: customer not anwser
              unsub();
              console.log("reload to live demo page");
              window.location.href = "https://penji.co/live-demo";
              
            }
          } else {
            console.log("Accept no changes");
          }
        });
      }

      // handle submit form join live demo
      function handleSubmitFormJoinLiveDemo(name, email, industry) {
        const ItemJSON = JSON.stringify({
          name: name,
          work_email: email,
          industry: industry,
        });
        console.log(ItemJSON);
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
          if (this.readyState == 4 && this.status == 200) {
            var result = JSON.parse(this.responseText);
            console.log(result);
            if (result.success == true) {
              handleProcessJoinLiveDemo(result.data, name, email, industry);
            }
          }
        };
        xhttp.open("POST", "https://api.penji.co/api-user/create-join-live-demo", true);
        xhttp.setRequestHeader("Content-type", "application/json");
        xhttp.send(ItemJSON);
      }

      // set cookie
      function setCookie(name, value, days) {
        let expires = "";
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/;domain=penji.co";
      }

      // delete cookie
      function deleteCookie(name) {
        document.cookie = name + "=; Max-Age=-99999999; path=/;domain=penji.co";
      }

      function loadProgressBar3Min(){
        const progressBar = document.getElementById('progress-bar');
        const duration = 180; // duration in seconds (3 minutes)
        let currentTime = 0;

        const interval = setInterval(() => {
            currentTime += 5;
            const progress = (currentTime / duration) * 100;
            progressBar.style.width = progress + '%';

            if (currentTime >= duration) {
                clearInterval(interval);
            }
        }, 5000);
      }

      //live demo js
      const firebaseConfig = {
        apiKey: "AIzaSyCjFKj-5lTNn40D9nwN3LE47ZXSdjg6pSw",
        authDomain: "penji30-455d7.firebaseapp.com",
        databaseURL: "https://penji30-455d7.firebaseio.com",
        projectId: "penji30-455d7",
        storageBucket: "penji30-455d7.appspot.com",
        messagingSenderId: "898355351636",
        appId: "1:898355351636:web:3f7bfabfb4827d0f",
        measurementId: "G-K3KE6D1MV1",
      };
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);
      let my_time_out, accept;
      livesubmitLoaded = true;
      observer.unobserve(entry.target);

      // wpcf7submit, wpcf7mailsent
      document.addEventListener(
        "wpcf7mailsent",
        function (event) {
          console.log("show step 2");
          document.getElementById("step1").classList.add("hidden");
          document.getElementById("step2").classList.remove("hidden");
          var inputs = event.detail.inputs;
          var arraylist = [];
          for (var i = 0; i < 3; i++) {
            arraylist.push(inputs[i].value);
          }
          const object_live_demo = { name: arraylist[0], email: arraylist[1] };
          setCookie('live_demo_penji', JSON.stringify(object_live_demo), 1);
          sendSubscribeToBrevo(117, arraylist[0], arraylist[1]);
          // sendGoogleSheetRedirect(arraylist[0], arraylist[1], arraylist[2]);
          handleSubmitFormJoinLiveDemo(
            arraylist[0],
            arraylist[1],
            arraylist[2]
          );
        },
        false
      );
    }
  });
};

// create observer
const observer = new IntersectionObserver(callback, options);

// loop through elements and observe them
document.querySelectorAll("#live-booking-demo-form").forEach((element) => {
  observer.observe(element);
});
