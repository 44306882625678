document.addEventListener("DOMContentLoaded", () => {
  const lazyVideos = document.querySelectorAll("video.lazy");

  const loadLazyVideo = (video) => {
    Array.from(video.children).forEach((source) => {
      if (source.tagName === "SOURCE") {
        source.src = source.dataset.src;
      }
    });
    video.currentTime = 0;
    video.load();
    video.classList.remove("lazy");
  };

  if ("IntersectionObserver" in window) {
    const lazyVideoObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadLazyVideo(entry.target);
          lazyVideoObserver.unobserve(entry.target);
        }
      });
    }, { rootMargin: '200px' });

    lazyVideos.forEach((lazyVideo) => lazyVideoObserver.observe(lazyVideo));
  }

  document.querySelectorAll(".data-micromodal-open").forEach((openModalButton) => {
    openModalButton.addEventListener("click", () => {
      const modalSelector = openModalButton.getAttribute("data-target");
      const modal = document.querySelector(modalSelector);

      if (modal) {
        modal.querySelectorAll("video.lazy").forEach(loadLazyVideo);
      }
    });
  });
});
