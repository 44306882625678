let clipboardLoaded = false;
document.addEventListener('DOMContentLoaded', () => {
  const copyButton = document.getElementById('copy-clipboard-button');
  const $triggerEl = document.getElementById('copy-clipboard-button');
  const $targetEl = document.getElementById('copy-text');

  if (!copyButton || !$triggerEl || !$targetEl) {
    return; // Early return if the necessary elements are not present
  }

  copyButton.addEventListener('click', async () => {
    if (!clipboardLoaded) {
      try {
        // Lazy-load the flowbite library and initialize clipboard functionality
        const { CopyClipboard } = await import('flowbite');
        const options1 = {
          contentType: 'input',
          htmlEntities: false, // infinite
          onCopy: () => {
            console.log('Text copied successfully!');
          }
        };
        const instanceOptions = {
          id: 'copy-clipboard-example',
          override: true
        };
        const clipboard = new CopyClipboard($triggerEl, $targetEl, options1, instanceOptions);
        
        // Assuming clipboard handles the copy operation internally
        clipboard.copy(); // Copy the text on button click
        clipboardLoaded = true;
      } catch (error) {
        console.error('Error loading clipboard functionality:', error);
      }
    }
  });
});
