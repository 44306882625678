document.addEventListener("DOMContentLoaded", function () {
  var element = document.getElementById("table-pricing");
  var btn_cate = document.getElementById("btn_category");
  if (element) {
    var observer = new IntersectionObserver(
      function (entries, observer) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            // Element is in the viewport, execute your code here

            /*
              Scroll table pricing
            */
            const table_pricing = document.getElementById("table-pricing");
            if(window.innerWidth < 768){
              if(table_pricing){
                table_pricing.scrollLeft += 240;
              }
            }
            /* 
            change price table pricing
            */
            const dropdowns = document.querySelectorAll(".dropdown");
            if (dropdowns) {
              dropdowns.forEach((dropdown) => {

                const select = document.querySelector(".select");
                const caret = document.querySelector(".caret");
                const menu = document.querySelector(".menu");
                const options = document.querySelectorAll(".menu li");
                const selected = document.querySelector(".selected");
                select.addEventListener("click", () => {
                  caret.classList.toggle("caret-rotate");
                  menu.classList.toggle("menu-open");
                });

                options.forEach((option) => {
                  option.addEventListener("click", () => {
                    var price_month = 995;
                    var price_quater = 2497;
                    var price_year = 4999;
                    var disc_month = 0;
                    var disc_quater = 0;
                    var disc_year = 0;
                    var save_month = 0;
                    var save_quater = 0;
                    var save_year = 0;
                    
                    const link_marketer = document.getElementById("checkoutLink_marketer");
                    const link_agency = document.getElementById("checkoutLink_agency");
                    const link_premium = document.getElementById("checkoutLink_premium");
                    
                    const url_marketer = new URL(link_marketer.href);
                    const url_agency = new URL(link_agency.href);
                    const url_premium = new URL(link_premium.href);

                    if(option.getAttribute("data-value") == 0.15) {
                      url_marketer.searchParams.set('duration', 'quarterly');
                      url_agency.searchParams.set('duration', 'quarterly');
                      url_premium.searchParams.set('duration', 'quarterly');
                    }else if(option.getAttribute("data-value") == 0.2){
                      url_marketer.searchParams.set('duration', 'yearly');
                      url_agency.searchParams.set('duration', 'yearly');
                      url_premium.searchParams.set('duration', 'yearly');
                    }else if(option.getAttribute("data-value") == 1){
                      url_marketer.searchParams.set('duration', 'monthly');
                      url_agency.searchParams.set('duration', 'monthly');
                      url_premium.searchParams.set('duration', 'monthly');
                    }
                    link_marketer.href = url_marketer.toString();
                    link_agency.href = url_agency.toString();
                    link_premium.href = url_premium.toString();

                    if (
                      option.getAttribute("data-value") == 0.15 ||
                      option.getAttribute("data-value") == 0.2
                    ) {
                      save_month = (
                        price_month * option.getAttribute("data-value")
                      ).toFixed(1);
                      disc_month = price_month - save_month;
                      document.getElementById("disc_month").innerText =
                        disc_month;
                      document.getElementById("price_saved_month").innerText =
                        save_month;
                      save_quater = (
                        price_quater * option.getAttribute("data-value")
                      ).toFixed(1);
                      disc_quater = price_quater - save_quater;
                      document.getElementById("disc_quater").innerText =
                        disc_quater;
                      document.getElementById("price_saved_quater").innerText =
                        save_quater;
                      save_year = (
                        price_year * option.getAttribute("data-value")
                      ).toFixed(1);
                      disc_year = price_year - save_year;
                      console.log(save_year);
                      document.getElementById("disc_year").innerText =
                        disc_year;
                      document.getElementById("price_saved_year").innerText =
                        save_year;
                    } else if (option.getAttribute("data-value") == 1) {
                      document.getElementById("disc_month").innerText =
                        price_month;
                      document.getElementById("disc_quater").innerText =
                        price_quater;
                      document.getElementById("disc_year").innerText =
                        price_year;

                      
                    }
                    const box_saved = document.querySelectorAll(".box-saved");
                    if (option.getAttribute("data-value") != 1) {
                      if (box_saved) {
                        box_saved.forEach((el) => {
                          el.classList.add("active");
                        });
                      }
                    } else {
                      if (box_saved) {
                        box_saved.forEach((el) => {
                          el.classList.remove("active");
                        });
                      }
                    }

                    selected.innerHTML = option.innerHTML;
                    caret.classList.remove("caret-rotate");
                    menu.classList.remove("menu-open");
                    options.forEach((option) =>
                      option.classList.remove("active")
                    );
                    option.classList.add("active");
                  });
                });
              });
            }
            // Add your code here

            // Stop observing the element since we only need to check once
            observer.unobserve(entry.target);
          }
        });
      },
      {
        root: null, // Use the viewport as the container
        rootMargin: "0px",
        threshold: 0.1, // Adjust the threshold as needed
      }
    );

    // Start observing the element
    observer.observe(element);
  }

  if (btn_cate) {
    var observer = new IntersectionObserver(
      function (entries, observer) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            observer.unobserve(entry.target);
            /* 
            change category
            */
            const btn_cate = document.getElementById("btn_category");
            const dropdown_cate = document.getElementById(
              "dropdown-pick-category"
            );
            const item_cates = document.querySelectorAll(".item-category");
            if (item_cates) {
              item_cates.forEach((item) => {
                item.addEventListener("click", (el) => {
                  item_cates.forEach((item) => {
                    item.classList.remove("checked");
                  });
                  btn_cate.innerHTML = item.innerHTML;
                  item.classList.add("checked");
                  dropdown_cate.classList.remove("block");
                  dropdown_cate.classList.add("hidden");
                });
              });
            }
            if (btn_cate) {
              btn_cate.addEventListener("click", () => {
                dropdown_cate.classList.remove("hidden");
                dropdown_cate.classList.add("block");
              });
            }
            if (btn_cate && dropdown_cate) {
              document.addEventListener("click", (event) => {
                if (
                  !btn_cate.contains(event.target) &&
                  !dropdown_cate.contains(event.target)
                ) {
                  dropdown_cate.classList.add("hidden");
                }
              });
            }
          }
        });
      },
      {
        root: null, // Use the viewport as the container
        rootMargin: "0px",
        threshold: 0.1, // Adjust the threshold as needed
      }
    );

    // Start observing the element
    observer.observe(btn_cate);
  }
});
