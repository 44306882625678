document.addEventListener('DOMContentLoaded', () => {
    const tippyContentElements = document.querySelectorAll('[data-tippy-content]');

    if (tippyContentElements.length > 0) {
        const loadTippy = () => {
            import("tippy.js").then((tippy) => {
                Promise.all([
                    import("tippy.js/dist/tippy.css"),
                    import("tippy.js/themes/light.css")
                ]).then(() => {
                    tippyContentElements.forEach((element) => {
                        tippy.default(element, { allowHTML: true, theme: 'light' });
                    });
                });
            });
        };

        // Use requestIdleCallback if available, else fallback to setTimeout
        if ('requestIdleCallback' in window) {
            requestIdleCallback(loadTippy);
        } else {
            setTimeout(loadTippy, 1);
        }
    }
});
