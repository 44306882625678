let lightboxLoaded = false;
const options = {
  root: null, // viewport
  rootMargin: '0px', // no margin
  threshold: 0.1 // 10% of the target visible
};

// Debounce function to limit the rate of execution
const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

// Callback function to handle intersection
const callback = debounce(async (entries, observer) => {
  for (let entry of entries) {
    if (entry.isIntersecting && !lightboxLoaded) {
      const lightboxModule = await import('lightbox2/dist/js/lightbox.min.js');
      lightboxModule.option({
        'disableScrolling': true
      });
      lightboxLoaded = true;
      observer.disconnect(); // Stop observing all elements after loading
      break; // Break loop since Lightbox is now loaded
    }
  }
}, 200); // Adjust the debounce wait time as needed

// Create observer
const observer = new IntersectionObserver(callback, options);

// Combine selectors to observe elements
const elementsToObserve = document.querySelectorAll('[data-lightbox], .art-gallery-x');
elementsToObserve.forEach(element => observer.observe(element));

// Click event logic
elementsToObserve.forEach(element => {
  element.addEventListener('click', async (event) => {
    event.preventDefault();
    if (!lightboxLoaded) {
      const lightboxModule = await import('lightbox2/dist/js/lightbox.min.js');
      lightboxModule.option({
        'disableScrolling': true
      });
      lightboxLoaded = true;
    }
  });
});
