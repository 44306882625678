import "./lazyload-image.js";
import "./lazyload-video.js";
import "./swiper.js";
import "lightbox2/dist/css/lightbox.min.css";
import "./modal.js";
import "./collapse.js";
import "./lightbox.js";
import "./copy-clipboard.js";
import "./tooltip.js";
import "./live-demo-submit.js";
// import "./live-demo-modal-submit.js";
import "./pricing-page-new.js";
document.addEventListener("DOMContentLoaded", function () {
  // Trì hoãn việc tải các module không khẩn cấp
  function runWhenIdle(callback) {
    if (window.requestIdleCallback) {
      window.requestIdleCallback(callback);
    } else {
      // Fallback cho các trình duyệt không hỗ trợ requestIdleCallback
      setTimeout(callback, 1000);
    }
  }

  runWhenIdle(() => {
    import("./smooth-scroll").then(({ scroll }) => {
      // Khởi tạo hoặc sử dụng scroll
    });
    import("./YouTubePopUp.js");

    // Play video demo
    const playVideoDemo = document.getElementById("play-video-demo");
    if (playVideoDemo) {
      playVideoDemo.addEventListener("click", function (event) {
        event.preventDefault();
        const player = new Vimeo.Player(document.getElementById("video-demo"));
        player.play();

        document.getElementById("video-demo").classList.remove("hidden");
        document.getElementById("image-video").classList.add("hidden");

        setTimeout(() => {
          document.getElementById("show-code").classList.add("active");
        }, 3000);
      });
    }

    // Popover live demo with event delegation
    const popover = document.querySelector(".popover");
    const wrapper = document.querySelector(".wrapper-popover");
    if (popover && wrapper) {
      document.body.addEventListener("click", function (event) {
        if (event.target.closest(".trigger-popover")) {
          console.log("trigger-popover");
          popover.classList.toggle("show-popover");
          wrapper.classList.toggle("active");
        } else if (
          event.target.matches(".close-button-popover") ||
          event.target === popover
        ) {
          popover.classList.remove("show-popover");
          wrapper.classList.remove("active");
        }
      });
    }

    // Modal live demo with event delegation
    const modal = document.querySelector(".modal-demo");
    if (modal) {
      document.body.addEventListener("click", function (event) {
        if (event.target.matches(".trigger")) {
          modal.classList.toggle("show-modal");
          document.body.style.overflow = "hidden";
        } else if (
          event.target.matches(".close-button") ||
          event.target === modal
        ) {
          modal.classList.remove("show-modal");
          document.body.style.overflow = "auto";
        }
      });
    }
  });

  // Tối ưu toggleMenu với caching
  window.toggleMenu = function () {
    const menu = document.querySelector(".menu-mobile");
    const burger = document.getElementById("burger-mobile");
    const body = document.body;

    if (burger) burger.classList.toggle("open");
    if (menu) menu.classList.toggle("active");
    body.style.overflow = body.style.overflow === "hidden" ? "auto" : "hidden";
  };
});
